import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';
import ProfileWrapper from '../profile-wrapper-component';
import ResetPasswordWrapper from '../reset-password-wrapper-component';
import MyAccountOrderDetailsWrapper from './my-account-order-details-wrapper-component';
import MyAccountOrdersWrapper from './my-account-orders-wrapper-component';
import MyAccountOverviewWrapper from './my-account-overview/my-account-overview-wrapper-component';
import MyAccountPartsListsWrapper from './my-account-parts-lists-wrapper-component';
import MyAccountPartsListDetailsWrapper from './my-account-parts-list-details-wrapper-component';

type RouterProps = {
  contextInfo: IContextInfo;
};

export const MyAccountRouter: FunctionComponent<RouterProps> = (props: RouterProps) => {
  return (
    <Switch>
      <Route path='(.*)/user/myaccount/resetPassword' exact render={() => <ResetPasswordWrapper />} />
      <Route path='(.*)/user/myaccount' exact render={() => <MyAccountOverviewWrapper />} />
      <Route path='(.*)/user/profile' exact render={() => <ProfileWrapper contextInfo={props.contextInfo} />} />
      <Route path='(.*)/user/myaccount/orders' exact render={() => <MyAccountOrdersWrapper />} />
      <Route path='(.*)/user/myaccount/order/:orderId' render={() => <MyAccountOrderDetailsWrapper contextInfo={props.contextInfo} />} />
      <Route path='(.*)/user/myaccount/partslists' exact render={() => <MyAccountPartsListsWrapper />} />
      <Route
        path='(.*)/user/myaccount/partslist/:partslistId'
        render={() => <MyAccountPartsListDetailsWrapper contextInfo={props.contextInfo} />}
      />
      <Route
        path='*'
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return <Redirect to='/error404/error404' />;
        }}
      />
    </Switch>
  );
};
