import React, { FunctionComponent } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { MyAccountPartsListDetailsPage, useMyAccountPartsListDetailsPageQuery } from '../../../generated/graphql';
import { PartsListDetailsPage } from '../../../rs-emd-ui-modules/src/components/user/my-account/my-account-parts-lists/parts-list-details-page/parts-list-details-page-component';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';

type MyAccountPartsListDetailsWrapperProps = {
  contextInfo: IContextInfo;
};

export const MyAccountPartsListDetailsWrapper: FunctionComponent<MyAccountPartsListDetailsWrapperProps> = (
  props: MyAccountPartsListDetailsWrapperProps
) => {
  const { partslistId } = useParams<{ partslistId?: string }>();
  let history = useHistory();
  let useCache: boolean = ((history.location.state as any)?.useCache ?? false) as boolean;

  const { loading, error, data } = useMyAccountPartsListDetailsPageQuery({
    variables: { partsListId: Number(partslistId ?? '') },
    fetchPolicy: useCache ? 'cache-first' : 'network-only',
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  else if (!data.myAccountPartsListDetailsPage.isSuccess) {
    if (data.myAccountPartsListDetailsPage.redirectUrl) {
      return <Redirect to={data.myAccountPartsListDetailsPage.redirectUrl} />;
    }
    return <Redirect to={`user/myaccount/partslists`} />;
  } else return <PartsListDetailsPage data={data.myAccountPartsListDetailsPage as MyAccountPartsListDetailsPage} />;
};

export default MyAccountPartsListDetailsWrapper;
