import loadable from '@loadable/component';

const OrderDetails = loadable(() => import(/* webpackChunkName: "my-account-order-details" */ './order-details-component'), {
  resolveComponent: (components) => components.OrderDetails,
});

const DetailsAccordion = loadable(
  () => import(/* webpackChunkName: "my-account-order-details" */ './details-accordion/details-accordion-component'),
  {
    resolveComponent: (components) => components.DetailsAccordion,
  }
);

const DeliveryDetails = loadable(
  () => import(/* webpackChunkName: "my-account-order-details" */ './delivery-details/delivery-details-component'),
  {
    resolveComponent: (components) => components.DeliveryDetails,
  }
);

export { OrderDetails, DetailsAccordion, DeliveryDetails };
