import loadable from '@loadable/component';

// Compare Alternatives
const Error404 = loadable(() => import(/* webpackChunkName: "error-404" */ './error-404/error-404-page-component'), {
  resolveComponent: (components) => components.Error404,
});

const TechnicalErrorPage = loadable(() => import(/* webpackChunkName: "error" */ './error/technical-error-component'), {
  resolveComponent: (components) => components.TechnicalErrorPage,
});

const Offline = loadable(() => import(/* webpackChunkName: "offline" */ './offline/offline-page-component'), {
  resolveComponent: (components) => components.Offline,
});

export { Error404, TechnicalErrorPage, Offline };
