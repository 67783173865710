import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useRegistrationTokenExpiredPageQuery } from '../../generated/graphql';
import { TokenExpiredPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';

type RegistrationTokenExpiredWrapperProps = {
  contextInfo: IContextInfo;
};

export const RegistrationTokenExpiredPageWrapper: FunctionComponent<RegistrationTokenExpiredWrapperProps> = (
  props: RegistrationTokenExpiredWrapperProps
) => {
  let location = useLocation();

  const token = new URLSearchParams(location.search).get('token');

  const { loading, error, data } = useRegistrationTokenExpiredPageQuery({
    ssr: true,
    skip: !token,
  });

  if (!token) {
    return <RedirectWithStatus to='' statusCode={302} />;
  } else if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  // if user is already logged in
  else if (!data.registrationTokenExpiredPage.isSuccess) {
    return <Redirect to={data.registrationTokenExpiredPage.redirectUrl ?? '/'} />;
  }

  return <TokenExpiredPage data={data.registrationTokenExpiredPage} />;
};

export default RegistrationTokenExpiredPageWrapper;
