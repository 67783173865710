import loadable from '@loadable/component';

const PartsListDetails = loadable(
  () => import(/* webpackChunkName: "my-account-parts-list-details" */ './parts-list-details-page-component'),
  {
    resolveComponent: (components) => components.PartsListDetailsPage,
  }
);

const PartsListDetailsTitleArea = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-parts-list-details" */ './parts-list-details-title-area/parts-list-details-title-area-component'
    ),
  {
    resolveComponent: (components) => components.PartsListDetailsTitleArea,
  }
);

const PartsListsSummary = loadable(
  () => import(/* webpackChunkName: "my-account-parts-list-details" */ './parts-lists-summary/parts-lists-summary-component'),
  {
    resolveComponent: (components) => components.PartsListsSummary,
  }
);

const PartsListAddResultModal = loadable(
  () =>
    import(/* webpackChunkName: "my-account-parts-list-details" */ './parts-list-add-result-modal/parts-list-add-result-modal-component'),
  {
    resolveComponent: (components) => components.PartsListAddResultModal,
  }
);

const PartListSummary = loadable(
  () => import(/* webpackChunkName: "my-account-parts-list-details" */ './part-list-summary/part-list-summary-component'),
  {
    resolveComponent: (components) => components.PartListSummary,
  }
);

const PartListSummaryItem = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-parts-list-details" */ './part-list-summary/part-list-summary-item/part-list-summary-item-component'
    ),
  {
    resolveComponent: (components) => components.PartListSummaryItem,
  }
);

export { PartListSummary, PartListSummaryItem, PartsListAddResultModal, PartsListDetails, PartsListDetailsTitleArea, PartsListsSummary };
