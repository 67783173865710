import { Dispatch } from 'react';
import { UserContextAction } from '../components/app/user-context';
import {
  BulkAddToBasketDocument,
  BulkAddToBasketMutation,
  BulkAddToBasketMutationVariables,
  BulkAddToBasketRequest,
  BulkAddToBasketResponse,
  DeletePartsListDocument,
  DeletePartsListLinesDocument,
  DeletePartsListLinesMutation,
  DeletePartsListLinesMutationVariables,
  DeletePartsListLinesRequest,
  DeletePartsListLinesResponse,
  DeletePartsListMutation,
  DeletePartsListMutationVariables,
  DeletePartsListResponse,
  EditPartsListDocument,
  EditPartsListMutation,
  EditPartsListMutationVariables,
  EditPartsListRequest,
  EditPartsListResponse,
  ManualAddToPartsListDocument,
  ManualAddToPartsListMutation,
  ManualAddToPartsListMutationVariables,
  ManualAddToPartsListRequest,
  ManualAddToPartsListResponse,
  MyAccountPartsListDetailsPage,
  MyAccountPartsListDetailsPageDocument,
  MyAccountPartsListDetailsPageQuery,
  MyAccountPartsListDetailsPageQueryVariables,
  MyAccountPartsListDetailsProductsDocument,
  MyAccountPartsListDetailsProductsQuery,
  MyAccountPartsListDetailsProductsQueryVariables,
  MyAccountPartsListsPage,
  MyAccountPartsListsPageDocument,
  MyAccountPartsListsPageQuery,
  MyAccountPartsListsPageQueryVariables,
  PartsListLineItem,
  PartsListsSort,
} from '../generated/graphql';
import { IPagination } from '../rs-emd-ui-modules/src/components/shared/page-controls/pagination.model';
import { graphQLService } from './graphql-service';
import { headerService } from './header-service';

export class MyAccountPartsListsService {
  async getPartsLists(
    pagination: IPagination,
    sort?: PartsListsSort,
    searchQuery?: string,
    forceNetwork?: boolean
  ): Promise<MyAccountPartsListsPage | undefined> {
    const result = await graphQLService.apolloClient.query<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>({
      query: MyAccountPartsListsPageDocument,
      variables: {
        sort: sort,
        page: { first: pagination.size, offset: pagination.offset },
        searchQuery: searchQuery,
      },
      fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
    });

    let res: MyAccountPartsListsPage | undefined = undefined;

    if (result.data) {
      res = result.data.myAccountPartsListsPage as MyAccountPartsListsPage;
    }

    return res;
  }

  async deletePartsList(partsListId: number): Promise<DeletePartsListResponse | undefined> {
    const result = await graphQLService.apolloClient.mutate<DeletePartsListMutation, DeletePartsListMutationVariables>({
      mutation: DeletePartsListDocument,
      variables: { input: { partsListId } },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result.data?.deletePartsList;
  }

  async bulkAddToBasket(req: BulkAddToBasketRequest): Promise<BulkAddToBasketResponse> {
    const result = await graphQLService.apolloClient.mutate<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>({
      mutation: BulkAddToBasketDocument,
      variables: { data: { requestId: req.requestId, lines: req.lines } },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result.data?.bulkAddToBasket as BulkAddToBasketResponse;
  }

  async getPartsListDetailsPage(partsListId: number): Promise<MyAccountPartsListDetailsPage> {
    const result = await graphQLService.apolloClient.query<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>(
      {
        query: MyAccountPartsListDetailsPageDocument,
        variables: { partsListId: partsListId },
        fetchPolicy: 'network-only',
      }
    );

    return result.data.myAccountPartsListDetailsPage as MyAccountPartsListDetailsPage;
  }

  async getPartsListProducts(partsListId: number): Promise<PartsListLineItem[] | undefined> {
    const result = await graphQLService.apolloClient.query<
      MyAccountPartsListDetailsProductsQuery,
      MyAccountPartsListDetailsProductsQueryVariables
    >({
      query: MyAccountPartsListDetailsProductsDocument,
      variables: {
        partsListId: partsListId,
      },
      fetchPolicy: 'network-only',
    });

    let res: PartsListLineItem[] | undefined = [];

    if (result.data && result.data.myAccountPartsListDetailsPage.details?.lines) {
      res = result.data.myAccountPartsListDetailsPage.details.lines as PartsListLineItem[];
    }

    return res;
  }

  async addToPartsList(
    req: ManualAddToPartsListRequest,
    userDispatchCallback: Dispatch<UserContextAction> | null
  ): Promise<ManualAddToPartsListResponse> {
    const result = await graphQLService.apolloClient.mutate<ManualAddToPartsListMutation, ManualAddToPartsListMutationVariables>({
      mutation: ManualAddToPartsListDocument,
      variables: { data: req },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    } else {
      // update header partslist totals if a new partslist was added
      if (result.data?.manualAddToPartsList.isSuccess && req.partsListName) headerService.getPartsListsTotal(userDispatchCallback);
    }

    return result.data?.manualAddToPartsList as ManualAddToPartsListResponse;
  }

  async deletePartsListLines(input: DeletePartsListLinesRequest): Promise<DeletePartsListLinesResponse | undefined> {
    const result = await graphQLService.apolloClient.mutate<DeletePartsListLinesMutation, DeletePartsListLinesMutationVariables>({
      mutation: DeletePartsListLinesDocument,
      variables: { input },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result.data?.deletePartsListLines;
  }

  async editPartsList(data: EditPartsListRequest): Promise<EditPartsListResponse | undefined> {
    const result = await graphQLService.apolloClient.mutate<EditPartsListMutation, EditPartsListMutationVariables>({
      mutation: EditPartsListDocument,
      variables: { data },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result.data?.editPartsList;
  }
}

export const myAccountPartsListsService = new MyAccountPartsListsService();
