import loadable from '@loadable/component';

const MyAccountPartsLists = loadable(() => import(/* webpackChunkName: "my-account-parts-lists" */ './my-account-parts-lists-component'), {
  resolveComponent: (components) => components.MyAccountPartsLists,
});

const PartListsList = loadable(
  () => import(/* webpackChunkName: "my-account-parts-lists" */ './my-account-part-lists-list/my-account-part-lists-list-component'),
  {
    resolveComponent: (components) => components.MyAccountPartListsList,
  }
);

const PartListsListRow = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-parts-lists" */ './my-account-part-lists-list/part-lists-list-row/part-lists-list-row-component'
    ),
  {
    resolveComponent: (components) => components.PartListsListRow,
  }
);

const PartListsListHeader = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-parts-lists" */ './my-account-part-lists-list/part-lists-list-header/part-lists-list-header-component'
    ),
  {
    resolveComponent: (components) => components.PartListsListHeader,
  }
);

const PartsListActionsModal = loadable(
  () => import(/* webpackChunkName: "my-account-parts-lists" */ './shared/parts-list-actions-modal/parts-list-actions-modal-component'),
  {
    resolveComponent: (components) => components.PartsListActionsModal,
  }
);

const PartListsProductList = loadable(
  () => import(/* webpackChunkName: "my-account-parts-lists" */ './part-lists-product-list/part-lists-product-list-component'),
  {
    resolveComponent: (components) => components.PartListsProductList,
  }
);

const PartsListActionsConfirmationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account-parts-lists" */ './shared/parts-list-actions-confirmation-modal/parts-list-actions-confirmation-modal-component'
    ),
  {
    resolveComponent: (components) => components.PartsListActionsConfirmationModal,
  }
);
export {
  MyAccountPartsLists,
  PartListsList,
  PartListsListHeader,
  PartListsListRow,
  PartListsProductList,
  PartsListActionsConfirmationModal,
  PartsListActionsModal,
};
