import React, { FunctionComponent } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { MyAccountPartsListsPage, useMyAccountPartsListsPageQuery } from '../../../generated/graphql';
import { MyAccountPartsLists } from '../../../rs-emd-ui-modules/src/components/user/my-account/my-account-parts-lists';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { getAccountPartsListsViewByCookie } from '../../../rs-emd-ui-modules/src/helpers/user-settings.utils';

export const MyAccountPartsListsWrapper: FunctionComponent = () => {
  let history = useHistory();
  let isListDeleted: boolean = ((history.location.state as any)?.listDeleted ?? false) as boolean;

  const { loading, error, data } = useMyAccountPartsListsPageQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.myAccountPartsListsPage.isSuccess) {
    return <Redirect to='/user/login?redirectTo=/user/myaccount' />;
  } else
    return (
      <MyAccountPartsLists
        data={data.myAccountPartsListsPage as MyAccountPartsListsPage}
        viewBy={getAccountPartsListsViewByCookie()}
        showListDeletedToast={isListDeleted}
      />
    );
};

export default MyAccountPartsListsWrapper;
